import format from 'date-fns/format'
import Axios, { CancelTokenSource } from 'axios'
import Cookies from 'js-cookie'
import startOfDay from 'date-fns/start_of_day'
import { LoginUser } from './types/auth'
import { AdminMachine, AdminUser, AdminMachinePayload, AdminTeam, AdminLegalEntity, AdminAbsence } from './types/admin'
import { User } from './types/users'
import { ExtShift } from './types/timeline'
import parse from 'date-fns/parse'

const axios = Axios.create()

const dateformat = /^\d{4}-\d{2}-\d{2}/
function reviver(key: string, value: any, keepTime: boolean) {
    if (typeof value === 'string' && dateformat.test(value)) {
        if (keepTime) {
            return parse(value)
        } else {
            return startOfDay(parse(value))
        }
    }
    return value
}

const transformResponse = (data: string, keepTime: boolean) => {
    if (data) {
        try {
            if (typeof data === 'string') {
                return JSON.parse(data, (key, value) => reviver(key, value, keepTime))
            } else {
                return JSON.parse(JSON.stringify(data), (key, value) => reviver(key, value, keepTime))
            }
        } catch (e) {
            console.log(e)
        }
    } else {
        return {}
    }
}

axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response
    },
    function(error) {
        if (error.response && error.response.status === 401) {
            Cookies.remove('session_token')
            localStorage.clear()
            window.location.reload()
        }

        if (error.response && error.response.status === 423 && error.response.data) {
            return Promise.reject({
                actions: error.response.data.map((x: { action: string; description: string }) => ({
                    action: x.action,
                    description: x.description
                }))
            })
        }

        if (error.response && error.response.data && error.response.data.message) {
            return Promise.reject({ message: error.response.data.message })
        }
        // Do something with response error
        return Promise.reject(error)
    }
)

export const login = (email: string, password: string) => {
    return axios
        .post<LoginUser>('/v2/login', { email, password })
        .then(res => {
            // @ts-ignore
            if (res.data.error && res.data.message) {
                // @ts-ignore
                throw new Error(res.data.message)
            }

            return res.data
        })
}

export const fetchCurrentUser = () => {
    return axios
        .get<LoginUser>('/v2/login/current', {
            headers: getHeaders()
        })
        .then(res => {
            // @ts-ignore
            if (res.data.error && res.data.message) {
                // @ts-ignore
                throw new Error(res.data.message)
            }

            return res.data
        })
}

export const updateCurrentUser = (u: Partial<LoginUser>) => {
    return axios
        .patch<LoginUser>('/v2/login/user', u, {
            headers: getHeaders()
        })
        .then(res => {
            // @ts-ignore
            if (res.data.error && res.data.message) {
                // @ts-ignore
                throw new Error(res.data.message)
            }

            return res.data
        })
}

const getHeaders = () => {
    return {
        'Authentication-Token': Cookies.get('session_token')
    }
}

export const Admin = {
    getMachines: (signal: CancelTokenSource) => {
        return axios
            .get<AdminMachine[]>('/v2/data/machine?_filter.orgtype=machine', {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
            .then(machines => machines.sort((a, b) => a.order - b.order))
    },
    updateMachines: (machines: AdminMachine[]) => {
        return axios
            .patch<{ success: AdminMachine[] }>('/v2/data/machine?_filter.orgtype=machine', machines, {
                headers: getHeaders()
            })
            .then(res => res.data.success)
    },
    updateTeams: (machines: AdminTeam[]) => {
        return axios
            .patch<{ success: AdminTeam[] }>('/v2/data/machine?_filter.orgtype=team', machines, {
                headers: getHeaders()
            })
            .then(res => res.data.success)
    },
    getAbsence: (signal: CancelTokenSource) => {
        return axios
            .get<AdminAbsence[]>('/v2/data/absencereason', {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    },
    createAbsence: (a: AdminAbsence) => {
        return axios
            .post<AdminAbsence>('/v2/data/absencereason', a, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    updateAbsence: (a: AdminAbsence) => {
        return axios
            .patch<AdminAbsence>('/v2/data/absencereason/' + a.id, a, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    removeAbsence: (a: AdminAbsence, action?: any) => {
        return axios
            .delete<AdminAbsence>('/v2/data/absencereason/' + a.id, {
                params: action,
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    getLegalEntities: (signal: CancelTokenSource) => {
        return axios
            .get<AdminLegalEntity[]>('/v2/data/legalentity', {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    },
    getLegalEntity: (id: number) => {
        return axios
            .get<AdminLegalEntity>('/v2/data/legalentity/' + id, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    updateLegalEntity: (x: AdminLegalEntity) => {
        return axios
            .patch<AdminLegalEntity>('/v2/data/legalentity/' + x.id, x, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    removeLegalEntity: (x: AdminLegalEntity) => {
        return axios
            .delete<AdminLegalEntity>('/v2/data/legalentity/' + x.id, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    createLegalEntity: (x: Partial<AdminLegalEntity>) => {
        return axios
            .post<AdminLegalEntity>('/v2/data/legalentity', x, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    getUsers: (signal: CancelTokenSource) => {
        return axios
            .get<AdminUser[]>('/v2/data/user', {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    },
    getUser: (id: number, signal: CancelTokenSource) => {
        return axios
            .get<AdminUser>('/v2/data/user/' + id, {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    },
    createUser: (user: Partial<AdminUser>) => {
        return axios
            .post<AdminUser>('/v2/data/user', user, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    updateUser: (user: AdminUser) => {
        return axios
            .put<AdminUser>('/v2/data/user/' + user.id, user, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    removeUser: (user: AdminUser) => {
        return axios
            .delete<AdminUser>('/v2/data/user/' + user.id, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    savePassword: (user: AdminUser, password: string) => {
        return axios
            .put<any>(
                '/v2/data/user/' + user.id + '/password',
                { password },
                {
                    headers: getHeaders()
                }
            )
            .then(res => res.data)
    },
    getMachine: (id: number, signal: CancelTokenSource) => {
        return axios
            .get<AdminMachine>('/v2/data/machine/' + id, {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    },
    createMachine: (machine: AdminMachinePayload) => {
        return axios
            .post('/v2/data/machine', machine, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    updateMachine: (machine: AdminMachine) => {
        return axios
            .patch('/v2/data/machine/' + machine.id, machine, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    deleteMachine: (machine: AdminMachine) => {
        return axios
            .delete('/v2/data/machine/' + machine.id, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    deleteTeam: (team: AdminTeam) => {
        return axios
            .delete('/v2/data/machine/' + team.id, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    updateTeam: (team: AdminTeam) => {
        return axios
            .patch('/v2/data/machine/' + team.id, team, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    createTeam: (team: AdminTeam) => {
        return axios
            .post('/v2/data/machine', team, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    getTeams: (signal: CancelTokenSource) => {
        return axios
            .get<AdminTeam[]>('/v2/data/machine?_filter.orgtype=team&_sort.order=asc', {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    },

    getSummary: (signal: CancelTokenSource, year: number) => {
        return axios
            .get('/v2/workorder/yearsummary/' + year, {
                headers: getHeaders(),
                cancelToken: signal.token
            })
            .then(res => res.data)
    }
}

export const timeline = {
    getMachines: (startdate: Date, enddate: Date, signal: CancelTokenSource) => {
        return axios
            .get<Machines>('/v2/timeline/machines', {
                headers: getHeaders(),
                params: {
                    startdate: format(startdate, 'YYYY-MM-DD'),
                    enddate: format(enddate, 'YYYY-MM-DD')
                },
                cancelToken: signal.token,
                transformResponse
            })
            .then(res => res.data.resource)
            .then(resource => {
                resource.machines.map(m => {
                    m.orders.map(o => {
                        o.timestamp = new Date().getTime()
                        return o
                    })
                    return m
                })

                return resource
            })
    },
    createWorkorder: (w: WorkOrder) => {
        const { id, ...payload } = w
        return axios
            .post<WorkOrder>('/v2/workorder/', payload, {
                transformResponse,
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    cutWorkorder: (w: Order, d: Date) => {
        return axios
            .post<[Order, Order]>(
                '/v2/workorder/' + w.id + '/cut',
                {
                    cutdate: format(d, 'YYYY-MM-DD')
                },
                {
                    headers: getHeaders(),
                    transformResponse
                }
            )
            .then(res => res.data)
    },
    moveWorkorder: (w: Order, action?: any) => {
        return axios
            .patch<WorkOrder>(
                '/v2/workorder/' + w.id,
                {
                    startdate: format(w.startdate, 'YYYY-MM-DD'),
                    enddate: format(w.enddate, 'YYYY-MM-DD'),
                    machine_id: w.machine_id
                },
                {
                    params: action,
                    headers: getHeaders(),
                    transformResponse
                }
            )
            .then(res => res.data)
    },
    updateWorkorder: (w: any, etag: string, action?: any) => {
        const payload = {
            ...w,
            startdate: format(w.startdate, 'YYYY-MM-DD'),
            enddate: format(w.enddate, 'YYYY-MM-DD'),
            days: w.days.map((x: any) => {
                x.date = format(new Date(x.date), 'YYYY-MM-DD')
                x.shifts = x.shifts.map((s: any) => {
                    s.date = x.date
                    return s
                })
                return x
            })
        }

        return axios
            .put<WorkOrder>('/v2/workorder/' + payload.id, payload, {
                transformResponse,
                params: action,
                headers: {
                    ...getHeaders(),
                    'If-Match': etag
                }
            })
            .then(res => ({
                ...res.data,
                etag: res.headers.etag
            }))
            .then(workorder => {
                workorder.days = workorder.days || []
                return { ...workorder }
            })
    },
    addPersonToShift: (id: number, shift_id: number, user_id: number) => {
        return axios
            .post<WorkOrder>(
                `/v2/workorder/${id}/days/shifts/${shift_id}/people/`,
                { user_id: user_id },
                {
                    headers: getHeaders(),
                    transformResponse
                }
            )
            .then(res => res.data)
    },
    getFreeshifts: (id: number, startdate: string) => {
        return axios
            .get<ExtShift[]>(`/v2/workperiod/${id}/freeshift/${startdate}`, {
                headers: getHeaders(),
                transformResponse
            })
            .then(res => res.data)
    },
    getWorkorder: (id: string) => {
        return axios
            .get<WorkOrder>('/v2/workorder/' + id, {
                headers: getHeaders(),
                transformResponse
            })
            .then(res => {
                return { ...res.data, etag: res.headers.etag }
            })
            .then(workorder => {
                workorder.days = workorder.days || []
                return workorder
            })
    },
    getShiftWorkorder: (id: string, shift_id: string) => {
        return axios
            .get<WorkOrder>('/v2/workorder/' + id + '/shift/' + shift_id, {
                headers: getHeaders(),
                transformResponse
            })
            .then(res => {
                return { ...res.data, etag: res.headers.etag }
            })
            .then(workorder => {
                workorder.days = workorder.days || []
                return workorder
            })
    },
    downloadWorkorder: (id: number) => {
        return axios({
            url: '/v2/workorder/' + id + '/csv',
            method: 'GET',
            headers: getHeaders(),
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            const fileName = response.headers['content-disposition'].split('filename=')[1]
            link.href = url
            link.setAttribute('download', fileName && fileName.replace(/"/g, ''))
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    },
    removeWorkorder: (w: WorkOrder, params?: { action: string; description: string }) => {
        return axios
            .delete<any>('/v2/workorder/' + w.id, {
                params,
                headers: getHeaders(),
                transformResponse
            })
            .then(res => res.data)
    },
    getTeams: (startdate: Date, enddate: Date, signal: CancelTokenSource) => {
        return axios
            .get<Teams>('/v2/timeline/teams', {
                params: {
                    startdate: format(startdate, 'YYYY-MM-DD'),
                    enddate: format(enddate, 'YYYY-MM-DD')
                },
                headers: getHeaders(),
                cancelToken: signal.token,
                transformResponse: (data: string) => transformResponse(data, true)
            })
            .then(res =>
                res.data.teams.map(team => {
                    team.people = team.people.map(x => {
                        x.workperiods = x.workperiods.map(y => {
                            y.shiftperiods = y.shiftperiods || []
                            return y
                        })

                        return x
                    })
                    return team
                })
            )
    },
    getUsers: (rolename: string, filters?: any) => {
        const params = {
            rolename,
            ...filters
        }

        return axios
            .get<User[]>('/v2/data/user', {
                params,
                headers: getHeaders(),
                transformResponse
            })
            .then(res => res.data)
    },
    getWorkperiod: (id: number) => {
        return axios
            .get<WorkperiodSingle>('/v2/workperiod/' + id, {
                headers: getHeaders(),
                transformResponse: data => transformResponse(data, true)
            })
            .then(res => res.data)
    },
    removeWorkperiod: (w: WorkperiodSingle) => {
        return axios
            .delete<any>('/v2/workperiod/' + w.id, {
                headers: getHeaders()
            })
            .then(res => res.data)
    },
    createWorkperiod: (
        workperiods: WorkperiodSingle[],
        team_id: number,
        params?: { description: string; action: string }
    ) => {
        let promises = []

        for (const w of workperiods) {
            const payload: any = {
                ...w,
                startdate: format(w.startdate, 'YYYY-MM-DD'),
                enddate: format(w.enddate, 'YYYY-MM-DD')
            }

            if (w.use_travel_time && w.leaving_home && w.returning_home) {
                payload.leaving_home = format(w.leaving_home, 'YYYY-MM-DD')
                payload.returning_home = format(w.returning_home, 'YYYY-MM-DD')
            }

            const { id, use_travel_time, ...rest } = payload
            promises.push(
                axios
                    .post<WorkperiodSingle>(
                        '/v2/workperiod/',
                        { ...rest, team_id },
                        {
                            params,
                            headers: getHeaders(),
                            transformResponse: data => transformResponse(data, true)
                        }
                    )
                    .then(res => res.data)
                    .then(w => {
                        w.shiftperiods = w.shiftperiods === null ? [] : w.shiftperiods
                        return w
                    })
            )
        }

        return Promise.all(promises)
    },
    updateWorkperiod: (w: any) => {
        const payload = {
            ...w,
            startdate: format(w.startdate, 'YYYY-MM-DD'),
            enddate: format(w.enddate, 'YYYY-MM-DD')
        }

        if (w.use_travel_time && w.leaving_home && w.returning_home) {
            payload.leaving_home = format(w.leaving_home, 'YYYY-MM-DD')
            payload.returning_home = format(w.returning_home, 'YYYY-MM-DD')
        }

        return axios
            .patch<WorkperiodSingle>('/v2/workperiod/' + payload.id, payload, {
                headers: getHeaders(),
                transformResponse: data => transformResponse(data, true)
            })
            .then(res => res.data)
    },
    cutWorkperiod: (w: Workperiod, d: Date) => {
        return axios
            .post<[Workperiod, Workperiod]>(
                '/v2/workperiod/' + w.id + '/cut',
                {
                    cutdate: format(d, 'YYYY-MM-DD')
                },
                {
                    headers: getHeaders(),
                    transformResponse
                }
            )
            .then(res => res.data)
    },
    moveWorkperiod: (w: Workperiod) => {
        return axios
            .patch<Workperiod>(
                '/v2/workperiod/' + w.id,
                {
                    startdate: format(w.startdate, 'YYYY-MM-DD'),
                    enddate: format(w.enddate, 'YYYY-MM-DD'),
                    leaving_home: w.leaving_home && format(w.leaving_home, 'YYYY-MM-DD'),
                    returning_home: w.returning_home && format(w.returning_home, 'YYYY-MM-DD')
                },
                {
                    headers: getHeaders(),
                    transformResponse
                }
            )
            .then(res => res.data)
    },

    removeWorkperiodsAfterDate: (user_id: number, startdate: string) => {
        return axios
            .delete(`/v2/workperiod/user/${user_id}/${startdate}`, {
                headers: getHeaders(),
                transformResponse
            })
            .then(res => res.data)
    },

    removeShiftperiod: (w: WorkperiodSingle) => {
        const payload = {
            ...w,
            startdate: format(w.startdate, 'YYYY-MM-DD'),
            enddate: format(w.enddate, 'YYYY-MM-DD')
        }

        if (payload.returning_home) {
            // @ts-ignore
            payload.returning_home = format(payload.returning_home, 'YYYY-MM-DD')
        }

        if (payload.leaving_home) {
            // @ts-ignore
            payload.leaving_home = format(payload.leaving_home, 'YYYY-MM-DD')
        }

        return axios
            .put<WorkperiodSingle>('/v2/workperiod/' + w.id, payload, {
                headers: getHeaders(),
                transformResponse
            })
            .then(res => res.data)
    },

    publishChanges: () => {
        return axios
            .post('/v2/workorder/changes/email', undefined, {
                headers: getHeaders()
            })
            .then(res => res.data)
    }
}

export default axios
