import React from 'react'
import Box, { BoxProps } from './Box'
import Text from './Text'
import { VStack, HStack } from './Layout'
import { scaleTime, ScaleTime, scaleLinear } from 'd3-scale'
import differenceInDays from 'date-fns/difference_in_days'
import startOfDay from 'date-fns/start_of_day'
import format from 'date-fns/format'
import addDays from 'date-fns/add_days'
import eachDay from 'date-fns/each_day'
import { getContrast } from 'polished'
import theme from '../theme'
import Draggable, { getSteppedValue, roundToNearestDay } from './Draggable'
import Tooltip from './Tooltip'
import { useHistory } from 'react-router-dom'
import { Warning, Pen } from './Icon'
import { endOfDay, isSameDay } from 'date-fns'
import MachineDialog from './MachineDialog'
import { timeline } from '../api'
import { useAsyncCallback } from '../hooks/useAsync'
import Loader from './Loader'
import Input from './Input'
import groupBy from 'lodash/groupBy'
import { ExtShift } from '../types/timeline'
import Button, { Touchable } from './Button'
import { TimelineContext } from '../pages/TimelinePage'
import { Translated } from '../context/LocaleContext'
import { UserContext } from '../App'
import Confirm from './Confirm'

const MACHINE_HEIGHT = 43

interface WorkOrderProps extends Order, BoxProps {
    scale: ScaleTime<number, number>
    isDragging: boolean
    dayWidth: number
}

const WorkOrder: React.FunctionComponent<WorkOrderProps> = ({
    children,
    name,
    isDragging = false,
    scale,
    type,
    dayWidth,
    startdate,
    enddate,
    days,
    id,
    ...props
}) => {
    const x = scale(startdate)

    return (
        <Box
            className="WorkOrder"
            bg="pale-olive"
            draggable={false}
            width="100%"
            zIndex={1}
            css={{ display: 'block', textDecoration: 'none' }}
            {...props}
        >
            <Text
                position="relative"
                left={x < 0 ? -x : 0}
                fontSize="xxs"
                fontWeight="semibold"
                width="100%"
                css={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}
                m={0}
            >
                {name ? name : 'Missing name'}
            </Text>
            <Box position="relative" height={18} left={-8}>
                {days
                    ? days.map((d, dIndex) => (
                          <Tooltip
                              key={dIndex}
                              disabled={!d.warning}
                              color="black"
                              bg="white"
                              placement="bottom"
                              interactive={true}
                              content={
                                  d.warning &&
                                  d.warning.map((w, index) => (
                                      <Text
                                          css={{
                                              textAlign: 'left',
                                              py: 1,
                                              '&:not(:last-child)': {
                                                  borderBottom: '1px solid',
                                                  borderBottomColor: 'midgrey'
                                              }
                                          }}
                                          key={'warning' + index}
                                          m={0}
                                      >
                                          {w.text}
                                      </Text>
                                  ))
                              }
                          >
                              <Text
                                  m={0}
                                  position="absolute"
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  left={8 + (scale(addDays(startdate, d.offset)) - scale(startdate)) + 'px'}
                                  width={dayWidth - 8}
                                  top={0}
                              >
                                  {d.warning && d.warning.length ? <Warning /> : d.hoursind}
                              </Text>
                          </Tooltip>
                      ))
                    : null}
            </Box>
            {children}
        </Box>
    )
}

interface ShiftProps extends Workperiod, BoxProps {
    onEditClick: (e: any) => void
    isCutting: boolean
    worker: People
    dayWidth: number
}

const Shift: React.FunctionComponent<ShiftProps> = ({
    children,
    isCutting,
    worker,
    id,
    time_type,
    shiftperiods,
    onEditClick,
    startdate,
    enddate,
    dayWidth,
    ...props
}) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const canEdit = role === 'admin' || role === 'staff_planner'

    const timelineContext = React.useContext(TimelineContext)
    const [machineDialogPayload, setMachineDialogPayload] = React.useState<{
        startdate: string
        x: number
        y: number
    } | null>(null)
    const [menuDialogPayload, setMenuDialogPayload] = React.useState<{ x: number; y: number } | null>(null)
    const [clickedDate, setClickedDate] = React.useState<string | null>(null)
    const getFreeshifts = useAsyncCallback(timeline.getFreeshifts)
    const [useWholePeriod, setUseWholePeriod] = React.useState(false)

    React.useEffect(() => {
        if (machineDialogPayload) {
            getFreeshifts.execute(id, machineDialogPayload.startdate)
        }
    }, [machineDialogPayload])

    type Machines = {
        [k: string]: ExtShift[]
    }

    const machines: Machines | null = getFreeshifts.result
        ? groupBy(getFreeshifts.result.filter(x => !x.useronshift), 'machine_name')
        : null

    const onMachineClick = async (name: string) => {
        const orders = groupBy((machines as Machines)[name], 'order_id')

        const promises = Object.keys(orders).flatMap(key => {
            const shifts: ExtShift[] = orders[key]
            return shifts.filter(x => !x.useronshift).map(s => timeline.addPersonToShift(s.order_id, s.id, worker.id))
        })

        await Promise.all(promises).then(() => {
            timelineContext.fetchTeams()
            timelineContext.fetchMachines()
            setMachineDialogPayload(null)
        })
    }

    const onMachineShiftClick = async (s: ExtShift) => {
        await timeline.addPersonToShift(s.order_id, s.id, worker.id)
        timelineContext.fetchTeams()
        timelineContext.fetchMachines()
        setMachineDialogPayload(null)
    }

    const style: any = {
        position: 'relative',
        fontSize: 'xss',
        fontWeight: 'semibold',
        width: '100%',
        bg: 'transparent',
        border: 'none',
        outline: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }

    return (
        <Box className="WorkPeriod" display="flex" bg="pale-olive" width="100%" zIndex={1} {...props}>
            <Box
                as="button"
                css={style}
                onClick={(e: any) => {
                    if (!canEdit) {
                        return false
                    }

                    if (isCutting) {
                        e.preventDefault()
                        onEditClick(e)
                    } else {
                        var dim = e.target.getBoundingClientRect()
                        var localX = e.clientX - dim.left

                        const scale = scaleTime()
                            .domain([startdate, enddate])
                            .range([0, dim.width])

                        setClickedDate(format(scale.invert(localX), 'YYYY-MM-DD'))
                        setMenuDialogPayload({ x: e.clientX, y: e.clientY })
                    }
                }}
            ></Box>
            {menuDialogPayload && (
                <MachineDialog
                    css={{
                        left: menuDialogPayload.x,
                        top: menuDialogPayload.y,
                        position: 'absolute',
                        m: 0
                    }}
                    onClose={() => {
                        setMenuDialogPayload(null)
                        return false
                    }}
                >
                    <VStack spacing={0}>
                        {time_type !== 'work_time' && (
                            <Button
                                variant="tertiary"
                                css={{ textAlign: 'left' }}
                                onClick={(e: any) => {
                                    onEditClick(e)
                                    setMenuDialogPayload(null)
                                }}
                            >
                                {Translated('edit_non_workperiod')}
                            </Button>
                        )}
                        {time_type === 'work_time' && (
                            <>
                                <Button
                                    variant="tertiary"
                                    css={{ textAlign: 'left' }}
                                    onClick={() => {
                                        if (clickedDate) {
                                            setMachineDialogPayload({
                                                startdate: clickedDate,
                                                x: menuDialogPayload.x,
                                                y: menuDialogPayload.y
                                            })
                                            setMenuDialogPayload(null)
                                        }
                                    }}
                                >
                                    {Translated('add_shift')}
                                </Button>
                                <Button
                                    variant="tertiary"
                                    css={{ textAlign: 'left' }}
                                    onClick={(e: any) => {
                                        onEditClick(e)
                                        setMenuDialogPayload(null)
                                    }}
                                >
                                    {Translated('edit_workperiod')}
                                </Button>
                            </>
                        )}
                    </VStack>
                </MachineDialog>
            )}
            {machineDialogPayload && (
                <MachineDialog
                    css={{
                        left: machineDialogPayload.x,
                        top: machineDialogPayload.y,
                        position: 'absolute',
                        m: 0
                    }}
                    onClose={() => {
                        setMachineDialogPayload(null)
                        return false
                    }}
                >
                    {getFreeshifts.loading && (
                        <Box p={2} position="relative" height={40}>
                            <Loader />
                        </Box>
                    )}
                    {getFreeshifts.error && (
                        <Text p={2} color="red">
                            {getFreeshifts.error.message}
                        </Text>
                    )}
                    {machines && (
                        <Box>
                            {Object.keys(machines).length === 0 && (
                                <Text p={2} fontWeight="semibold" m={0}>
                                    {Translated('no_shifts_available')}
                                </Text>
                            )}
                            {Object.keys(machines).map(x => {
                                const shifts = machines[x] as ExtShift[]

                                if (useWholePeriod) {
                                    return (
                                        <Touchable
                                            width="100%"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                            height={40}
                                            onClick={() => {
                                                onMachineClick(x)
                                            }}
                                            css={{
                                                '&:hover': { bg: 'lightgrey' },
                                                textAlign: 'left',
                                                px: 2,
                                                py: 2,
                                                '&:not(:last-child)': {
                                                    borderBottom: 1,
                                                    borderBottomColor: 'midgrey'
                                                }
                                            }}
                                        >
                                            <Text fontWeight="semibold" lineHeight="tight" m={0}>{`${x}`}</Text>
                                        </Touchable>
                                    )
                                }

                                return shifts.map(s => (
                                    <Touchable
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        height={40}
                                        onClick={() => {
                                            onMachineShiftClick(s)
                                        }}
                                        css={{
                                            position: 'relative',
                                            '&:hover': { bg: 'lightgrey' },
                                            textAlign: 'left',
                                            px: 2,
                                            py: 2,
                                            '&:not(:last-child)': {
                                                borderBottom: 1,
                                                borderBottomColor: 'midgrey'
                                            }
                                        }}
                                    >
                                        <HStack spacing={1}>
                                            <Box width="12px" height="12px" borderRadius="100%" bg={s.machine_color} />
                                            <Text fontWeight="semibold" lineHeight="tight" m={0}>
                                                {`${x}`}
                                                <Text
                                                    as="span"
                                                    fontWeight="semibold"
                                                    ml={2}
                                                    fontSize="xs"
                                                    color="brownish-grey"
                                                >
                                                    {`${format(s.date, 'MM-DD')} ${s.starttime}`}
                                                </Text>
                                            </Text>
                                        </HStack>
                                    </Touchable>
                                ))
                            })}
                            {Object.keys(machines).length > 0 && (
                                <Box p={2} pb={4}>
                                    <Input.Checkbox
                                        checked={useWholePeriod}
                                        onChange={(e: any) => {
                                            setUseWholePeriod(e.target.checked)
                                        }}
                                    >
                                        <Text mb={0}>Fyll hela arbetsperioden</Text>
                                    </Input.Checkbox>
                                </Box>
                            )}
                        </Box>
                    )}
                </MachineDialog>
            )}
        </Box>
    )
}

interface ShiftPeriodProps extends BoxProps {}

const ShiftPeriod: React.FunctionComponent<ShiftPeriodProps> = ({ children, ...props }) => {
    return (
        <Box className="ShiftPeriod" bg="pale-olive" height="100%" zIndex={1} {...props}>
            {children}
        </Box>
    )
}

interface MachineTimelineProps extends BoxProps {
    start: Date
    end: Date
    mode: string
    machines: Machine[]
}

interface MachinesListProps extends BoxProps {
    scale: ScaleTime<number, number>
    machines: Machine[]
    dayWidth: number
    isCutting: boolean
    days: Date[]
    onPositionChange: (
        start: Date,
        machineIndex: number,
        newMachineIndex: number,
        workOrderIndex: number,
        dayCount: number
    ) => void
    onWorkOrderCut: (dayindex: number, workOrderIndex: number, machineIndex: number) => void
}

const MachinesList: React.FC<MachinesListProps> = ({
    scale,
    machines,
    days,
    dayWidth,
    isCutting,
    onPositionChange: onPosChange,
    onWorkOrderCut: onCut
}) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const canEdit = role === 'admin' || role === 'staff_planner'

    const history = useHistory()
    const onPositionChange = React.useCallback(
        (x: number, y: number, width: number, machineIndex: number, projectIndex: number) => {
            const newStart = roundToNearestDay(scale.invert(x))
            const dayCount = getSteppedValue(width - dayWidth, dayWidth) / dayWidth
            const localY = machineIndex * MACHINE_HEIGHT
            const newMachineIndex = Math.floor((localY + y) / MACHINE_HEIGHT)

            onPosChange(newStart, machineIndex, newMachineIndex, projectIndex, dayCount)
        },
        [scale, dayWidth, onPosChange]
    )

    const onWorkOrderCut = (e: any, workOrderIndex: number, machineIndex: number) => {
        const box = e.target.getBoundingClientRect()
        const dayIndex = getSteppedValue(e.clientX - box.x, dayWidth) / dayWidth
        onCut(dayIndex, workOrderIndex, machineIndex)
    }

    return (
        <Box position="relative" width="100%" pt={30} pb="4px" css={{ overflow: 'hidden' }}>
            {machines.map((machine, machineIndex) => {
                return (
                    <Box
                        position="relative"
                        height={MACHINE_HEIGHT - 4}
                        mb="4px"
                        className="machine__row"
                        key={machine.id}
                    >
                        <>
                            {days.map(d => (
                                <Box
                                    key={d.toString()}
                                    position="absolute"
                                    left={scale(d)}
                                    width={dayWidth}
                                    zIndex={0}
                                    opacity={0.2}
                                    onClick={(e: any) => {
                                        e.preventDefault()

                                        if (canEdit) {
                                            history.push(
                                                `/timeline/workorder?machine_id=${machine.id}&start=${format(
                                                    d,
                                                    'YYYY-MM-DD'
                                                )}`
                                            )
                                        }
                                    }}
                                    css={{
                                        transition: 'background-color .3s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bg: machine.color
                                        }
                                    }}
                                    height={MACHINE_HEIGHT - 4}
                                ></Box>
                            ))}
                            {machine.orders.map((workOrder, workOrderIndex) => {
                                const colors = theme.colors as any
                                const bg = workOrder.type === 'prospect' ? colors['prospekt_bg'] : machine.color
                                const contrast = getContrast(bg, 'rgba(51, 51, 51, 0.8)')
                                const color = contrast > 5 ? 'rgba(51, 51, 51, 0.8)' : 'rgba(255, 255, 255, 0.8)'

                                const width = Math.round(
                                    scale(endOfDay(workOrder.enddate)) - scale(startOfDay(workOrder.startdate))
                                )

                                const localY = machineIndex * MACHINE_HEIGHT
                                const maxY = (machines.length - 1) * MACHINE_HEIGHT

                                const left = scale(startOfDay(workOrder.startdate))

                                const clickScale = scaleLinear()
                                    .domain([0, width])
                                    .rangeRound([0, width / dayWidth])

                                return (
                                    <Draggable
                                        disableDrag={!canEdit}
                                        disableResize={!canEdit}
                                        scale={scale}
                                        bounds={{
                                            y: [-localY, maxY - localY],
                                            x: [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]
                                        }}
                                        isCutting={isCutting}
                                        index={machineIndex}
                                        onPositionChange={(x: number, y: number, w: number) =>
                                            onPositionChange(x, y, w, machineIndex, workOrderIndex)
                                        }
                                        left={left}
                                        top={0}
                                        zIndex={workOrder.type === 'prospect' ? 1 : 2}
                                        height={MACHINE_HEIGHT - 4}
                                        width={width}
                                        step={dayWidth}
                                        onTap={(e: any) => {
                                            if (isCutting) {
                                                onWorkOrderCut(e, workOrderIndex, machineIndex)
                                            } else {
                                                let index

                                                /// user clicked on day
                                                if (/Text/.test(e.target.className)) {
                                                    const target = e.target.parentNode
                                                    const dim = target.getBoundingClientRect()
                                                    const x = e.clientX - dim.left
                                                    index = clickScale(x)
                                                } else {
                                                    const target = e.target
                                                    const dim = target.getBoundingClientRect()
                                                    const x = e.clientX - dim.left
                                                    index = clickScale(x)
                                                }

                                                if (workOrder.id !== -1) {
                                                    history.push(
                                                        '/timeline/workorder/' + workOrder.id + '?dayIndex=' + index
                                                    )
                                                }
                                            }
                                        }}
                                        key={`${machineIndex} ${workOrder.name} ${workOrderIndex} ${left} ${width} ${workOrder.timestamp}`}
                                    >
                                        {isDragging => (
                                            <WorkOrder
                                                bg={bg}
                                                color={workOrder.type === 'prospect' ? 'prospekt' : color}
                                                scale={scale}
                                                dayWidth={dayWidth}
                                                {...workOrder}
                                                isDragging={isDragging}
                                                py={1}
                                                px={2}
                                                height="100%"
                                                overflow="hidden"
                                            ></WorkOrder>
                                        )}
                                    </Draggable>
                                )
                            })}
                        </>
                    </Box>
                )
            })}
        </Box>
    )
}

export const MachineTimeline: React.FunctionComponent<MachineTimelineProps> = ({
    start,
    end,
    mode,
    machines,
    onPositionChange,
    onWorkOrderCut,
    ...props
}) => {
    const range = [start, end]
    const isCutting = mode === 'cut'
    const width = getSteppedValue(differenceInDays(range[1], range[0]) * 40, 40)

    const scale = scaleTime()
        .domain(range)
        .rangeRound([0, width])

    const days = eachDay(range[0], range[1])
    const dayWidth = scale(startOfDay(days[1])) - scale(startOfDay(days[0]))

    return (
        <VStack spacing={3} width={width} alignSelf="flex-start" {...props}>
            <Box position="relative" width="100%" height="100%" overflow="hidden">
                <Box position="relative" width="100%" bg="offwhite">
                    <Box position="absolute" height="100%" width="100%" left={0} top={0} css={{ overflow: 'hidden' }}>
                        {days.map((x, index) => (
                            <Box
                                key={index}
                                top={0}
                                height="100%"
                                width={dayWidth}
                                borderLeft={1}
                                borderColor="midgrey"
                                style={{
                                    left: scale(startOfDay(x))
                                }}
                                opacity={0.5}
                                position="absolute"
                            ></Box>
                        ))}
                    </Box>
                    <MachinesList
                        key={scale.domain().toString() + mode}
                        onWorkOrderCut={onWorkOrderCut}
                        onPositionChange={onPositionChange}
                        days={days}
                        isCutting={isCutting}
                        machines={machines}
                        scale={scale}
                        dayWidth={dayWidth}
                    />
                </Box>
            </Box>
        </VStack>
    )
}

const ShiftPeriods: React.FC<{
    shiftperiods: Shiftperiod[]
    workerIndex: number
    teamIndex: number
    workperiodIndex: number
    height: number
    scale: ScaleTime<number, number>
}> = ({ shiftperiods, scale, height, teamIndex, workerIndex, workperiodIndex }) => {
    const history = useHistory()

    const { mode, onShiftPeriodRemoved, onShiftRemoved } = React.useContext(TeamsContext) as TeamsContextState
    const [menuDialogPayload, setMenuDialogPayload] = React.useState<{
        x: number
        y: number
        shift: Shiftperiod
    } | null>(null)
    const [clickedDate, setClickedDate] = React.useState<Date | null>(null)

    return (
        <Box css={{ cursor: 'pointer' }}>
            {shiftperiods.map((shift, i) => {
                const contrast = getContrast(shift.machine_color, 'rgba(51, 51, 51, 0.8)')
                const color = contrast > 5 ? 'rgba(51, 51, 51, 0.8)' : 'rgba(255, 255, 255, 0.8)'

                const totalWidth = scale(endOfDay(shift.enddate)) - scale(startOfDay(shift.startdate)) - 6

                return (
                    <ShiftPeriod
                        key={shift.order_id + shift.name}
                        bg={shift.machine_color}
                        color={color}
                        position="absolute"
                        as={Text}
                        fontSize="xxs"
                        fontWeight="semibold"
                        onClick={(e: any) => {
                            if (mode === 'erase') {
                                var dim = e.target.getBoundingClientRect()
                                var localX = e.clientX - dim.left

                                const scale = scaleTime()
                                    .domain([shift.startdate, shift.enddate])
                                    .range([0, dim.width])

                                setClickedDate(scale.invert(localX))
                                setMenuDialogPayload({ x: e.clientX, y: e.clientY, shift })
                            } else {
                                history.push('/timeline/operator-workorder/' + shift.order_id + "?shift_id=" + shift.workperiod_id.toString())
                            }
                        }}
                        px={2}
                        py={1}
                        m={0}
                        css={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                        style={{
                            top: 0,
                            left: scale(shift.startdate) + 1,
                            width: totalWidth,
                            height: height - 2
                        }}
                    >
                        {shift.name}

                        {menuDialogPayload && clickedDate && (
                            <MachineDialog
                                css={{
                                    left: menuDialogPayload.x,
                                    top: menuDialogPayload.y,
                                    position: 'absolute',
                                    m: 0
                                }}
                                onClose={() => {
                                    setMenuDialogPayload(null)
                                    return false
                                }}
                            >
                                <VStack spacing={0}>
                                    <Button
                                        variant="tertiary"
                                        css={{ textAlign: 'left' }}
                                        onClick={(e: any) => {
                                            onShiftPeriodRemoved(
                                                teamIndex,
                                                workerIndex,
                                                workperiodIndex,
                                                menuDialogPayload.shift,
                                                i
                                            )
                                            setMenuDialogPayload(null)
                                            setClickedDate(null)
                                        }}
                                    >
                                        {Translated('remove_shiftperiod')}
                                    </Button>
                                    <Button
                                        variant="tertiary"
                                        css={{ textAlign: 'left' }}
                                        onClick={() => {
                                            setMenuDialogPayload(null)
                                            onShiftRemoved(teamIndex, workerIndex, workperiodIndex, clickedDate, i)
                                            setClickedDate(null)
                                        }}
                                    >
                                        {Translated('remove_single_shift', [format(clickedDate, 'YYYY-MM-DD')])}
                                    </Button>
                                </VStack>
                            </MachineDialog>
                        )}
                    </ShiftPeriod>
                )
            })}
        </Box>
    )
}

const Workperiod: React.FC<{
    workperiod: Workperiod
    worker: People
    teamIndex: number
    workerIndex: number
    workperiodIndex: number
}> = ({ workperiod: w, worker, teamIndex, workerIndex, workperiodIndex }) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const canEdit = role === 'admin' || role === 'staff_planner'

    const { scale, dayWidth, height, isCutting, onWorkperiodTap, onPositionChange } = React.useContext(
        TeamsContext
    ) as TeamsContextState

    const workPeriodLeft = scale(startOfDay(w.startdate))

    const getDateRangeFromShifts = (shiftperiods: Shiftperiod[]) => {
        const timestamps = shiftperiods.flatMap(x => [x.startdate.getTime(), x.enddate.getTime()])

        const min = Math.min(...timestamps)
        const max = Math.max(...timestamps)

        if (min !== Infinity && max !== -Infinity) {
            return [startOfDay(new Date(min)), endOfDay(new Date(max))]
        }

        return []
    }

    const shiftRange = getDateRangeFromShifts(w.shiftperiods)

    const xBounds: [number, number] =
        shiftRange.length > 0 ? [scale(shiftRange[0]), scale(shiftRange[1])] : [9999, -9999]

    const width = Math.round(scale(endOfDay(w.enddate)) - scale(startOfDay(w.startdate)))

    const bg =
        w.time_type === 'work_time'
            ? theme.colors['lightgrey']
            : w.time_type === 'unconfirmed_vacation_time'
            ? theme.colors['brownish-grey']
            : theme.colors['black']
    const contrast = getContrast(bg, 'rgba(51, 51, 51, 0.8)')
    const color = contrast > 5 ? 'rgba(51, 51, 51, 0.8)' : 'rgba(255, 255, 255, 0.8)'

    const onEditClick = (e: any) => {
        onWorkperiodTap(e, teamIndex, workerIndex, workperiodIndex, w)
    }

    return (
        <Box>
            <Draggable
                disableResize={!canEdit}
                scale={scale}
                disableDrag={w.shiftperiods.length > 0 || !canEdit}
                key={`${workPeriodLeft} ${width} ${w.id} ${isCutting}`}
                bounds={{
                    y: [0, 0],
                    x: xBounds
                }}
                isCutting={isCutting}
                isRelative={true}
                onTap={(e: any) => {}}
                height={height - 2}
                index={teamIndex}
                left={workPeriodLeft}
                top={0}
                rowHeight={height}
                width={width}
                onPositionChange={(x: number, y: number, width: number) =>
                    onPositionChange(x, y, width, workerIndex, workperiodIndex, teamIndex)
                }
                step={dayWidth}
            >
                {isDragging => (
                    <Shift
                        isCutting={isCutting}
                        worker={worker}
                        dayWidth={dayWidth}
                        bg={bg}
                        color={color}
                        onEditClick={onEditClick}
                        {...w}
                        py={1}
                        px={2}
                        isDragging={isDragging}
                        height="100%"
                    ></Shift>
                )}
            </Draggable>
            <ShiftPeriods
                teamIndex={teamIndex}
                workerIndex={workerIndex}
                workperiodIndex={workperiodIndex}
                scale={scale}
                height={height}
                shiftperiods={w.shiftperiods}
            />
        </Box>
    )
}

interface WorkperiodsProps {
    workperiods: Workperiod[]
    worker: People
    teamIndex: number
    workerIndex: number
}

const Workperiods: React.FC<WorkperiodsProps> = ({ worker, workperiods, children, teamIndex, workerIndex }) => {
    const { height } = React.useContext(TeamsContext) as TeamsContextState

    return (
        <Box position="relative" height={height}>
            {workperiods.map((w, workperiodIndex) => (
                <Workperiod
                    key={w.id}
                    worker={worker}
                    teamIndex={teamIndex}
                    workerIndex={workerIndex}
                    workperiodIndex={workperiodIndex}
                    workperiod={w}
                />
            ))}
            {children}
        </Box>
    )
}

interface TeamsContextState {
    dayWidth: number
    scale: ScaleTime<number, number>
    height: number

    mode: 'edit' | 'cut' | 'erase'
    isCutting: boolean
    onWorkperiodTap: (e: any, teamIndex: number, workerIndex: number, workperiodIndex: number, w: Workperiod) => void
    onShiftPeriodRemoved: (
        teamIndex: number,
        workerIndex: number,
        workperiodIndex: number,
        x: Shiftperiod,
        index: number
    ) => void
    onShiftRemoved: (teamIndex: number, workerIndex: number, workperiodIndex: number, date: Date, index: number) => void
    onPositionChange: (
        x: number,
        y: number,
        width: number,
        workerIndex: number,
        workperiodIndex: number,
        teamIndex: number
    ) => void
}

const TeamsContext = React.createContext<TeamsContextState | null>(null)

interface TeamsTimelineProps {
    zoomLevel: 1 | 2
    start: Date
    end: Date
    teamsClosed: number[]
    data: Team[]
    mode: 'edit' | 'cut' | 'erase'
    onWorkperiodCut: (dayIndex: number, teamIndex: number, personIndex: number, workperiodIndex: number) => void
    onWorkperiodRemoved: () => void
    onShiftPeriodRemoved: (
        teamIndex: number,
        workerIndex: number,
        workperiodIndex: number,
        x: Shiftperiod,
        index: number
    ) => void
    onShiftRemoved: (teamIndex: number, workerIndex: number, workperiodIndex: number, date: Date, index: number) => void
    onPositionChange: (
        start: Date,
        teamIndex: number,
        workerIndex: number,
        workperiodIndex: number,
        dayCount: number
    ) => void
}

export const TeamsTimeline: React.FunctionComponent<TeamsTimelineProps> = ({
    zoomLevel,
    start,
    end,
    teamsClosed,
    data: teams,
    mode,
    onPositionChange: onPosChange,
    onShiftPeriodRemoved,
    onShiftRemoved,
    onWorkperiodCut: onCut,
    onWorkperiodRemoved,
    ...props
}) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const canEdit = role === 'admin' || role === 'staff_planner'
    const removeWorkperiods = useAsyncCallback(timeline.removeWorkperiodsAfterDate)

    const [removeWorkperiodsPayload, setRemoveWorkperiodsPayload] = React.useState<{
        name: string
        id: number
        startdate: Date
    } | null>(null)

    const history = useHistory()
    const range = [start, end]
    const isCutting = mode === 'cut'
    const SHIFT_HEIGHT = zoomLevel === 1 ? 26 : 16

    const width = getSteppedValue(differenceInDays(range[1], range[0]) * 40, 40)

    const scale = scaleTime()
        .domain(range)
        .rangeRound([0, width])

    const days = eachDay(range[0], range[1])
    const dayWidth = scale(startOfDay(days[1])) - scale(startOfDay(days[0]))

    const onDayClick = React.useCallback(
        (e: any, worker: People, team: Team, d: Date) => {
            e.preventDefault()
            if (!isCutting && canEdit) {
                history.push(
                    `/timeline/workperiod?user_id=${worker.id}&team_id=${team.id}&start=${format(d, 'YYYY-MM-DD')}`
                )
            }
        },
        [history, isCutting, canEdit]
    )

    const onPositionChange = React.useCallback(
        (x: number, y: number, width: number, workerIndex: number, workperiodIndex: number, teamIndex: number) => {
            const newStart = roundToNearestDay(scale.invert(x))
            const dayCount = getSteppedValue(width - dayWidth, dayWidth) / dayWidth

            onPosChange(newStart, teamIndex, workerIndex, workperiodIndex, dayCount)
        },
        [scale, dayWidth, onPosChange]
    )

    const onWorkperiodTap = (
        e: any,
        teamIndex: number,
        workerIndex: number,
        workperiodIndex: number,
        w: Workperiod
    ) => {
        if (isCutting) {
            const box = e.target.getBoundingClientRect()
            const dayIndex = getSteppedValue(e.clientX - box.x, dayWidth) / dayWidth
            onCut(dayIndex, teamIndex, workerIndex, workperiodIndex)
        } else {
            const team = teams[teamIndex]
            const worker = team.people[workerIndex]

            if (w.id !== -1) {
                history.push(`/timeline/workperiod/${w.id}?user_id=${worker.id}&team_id=${team.id}`)
            }
        }
    }

    return (
        <TeamsContext.Provider
            value={{
                mode,
                onShiftPeriodRemoved,
                onShiftRemoved,
                dayWidth,
                scale,
                isCutting,
                height: SHIFT_HEIGHT,
                onPositionChange,
                onWorkperiodTap
            }}
        >
            <VStack spacing={3} width={width} alignSelf="flex-start">
                <Box position="relative" width="100%" height="100%" overflow="hidden">
                    <Box position="relative" width="100%" bg="offwhite" pt={28} pb={11}>
                        <Box
                            position="absolute"
                            height="100%"
                            width="100%"
                            left={0}
                            top={0}
                            css={{ overflow: 'hidden' }}
                            key="days"
                        >
                            {days.map((x, index) => (
                                <Box
                                    key={x.toString()}
                                    top={0}
                                    height="100%"
                                    opacity={0.5}
                                    width={dayWidth}
                                    borderLeft={1}
                                    borderColor="midgrey"
                                    style={{
                                        left: scale(startOfDay(x))
                                    }}
                                    position="absolute"
                                ></Box>
                            ))}
                        </Box>

                        {teams.map((team, teamIndex) => {
                            return (
                                <Box
                                    key={team.name + scale.domain().toString() + teamIndex}
                                    position="relative"
                                    bg="rgba(0, 0, 0, 0.03)"
                                    mt={SHIFT_HEIGHT}
                                    width="100%"
                                    css={{ overflow: 'hidden' }}
                                >
                                    {team.people.map((worker, workerIndex) => {
                                        if (teamsClosed.indexOf(team.id) !== -1) {
                                            return null
                                        }

                                        return (
                                            <Workperiods
                                                worker={worker}
                                                key={workerIndex}
                                                workperiods={worker.workperiods}
                                                workerIndex={workerIndex}
                                                teamIndex={teamIndex}
                                            >
                                                {days.map(d => (
                                                    <Box
                                                        key={d.toString()}
                                                        position="absolute"
                                                        width={dayWidth}
                                                        zIndex={0}
                                                        opacity={0.2}
                                                        className="WorkperiodDay"
                                                        onClick={(e: any) => {
                                                            if (mode === 'erase') {
                                                                setRemoveWorkperiodsPayload({
                                                                    name: worker.name,
                                                                    id: worker.id,
                                                                    startdate: d
                                                                })
                                                            } else {
                                                                onDayClick(e, worker, team, d)
                                                            }
                                                        }}
                                                        css={{
                                                            transition: 'background-color .3s ease',
                                                            '&:hover': {
                                                                bg: 'midgrey'
                                                            }
                                                        }}
                                                        style={{
                                                            left: scale(d)
                                                        }}
                                                        height={SHIFT_HEIGHT}
                                                    ></Box>
                                                ))}
                                            </Workperiods>
                                        )
                                    })}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </VStack>
            {removeWorkperiodsPayload && (
                <Confirm
                    loading={removeWorkperiods.loading}
                    message={`Are you sure that you want to remove all workperiods for ${
                        removeWorkperiodsPayload.name
                    } after ${format(removeWorkperiodsPayload.startdate, 'YYYY-MM-DD')}?`}
                    onOk={() => {
                        removeWorkperiods
                            .execute(
                                removeWorkperiodsPayload.id,
                                format(removeWorkperiodsPayload.startdate, 'YYYY-MM-DD')
                            )
                            .then(() => {
                                setRemoveWorkperiodsPayload(null)
                                onWorkperiodRemoved()
                            })
                    }}
                    onCancel={() => {
                        setRemoveWorkperiodsPayload(null)
                    }}
                />
            )}
        </TeamsContext.Provider>
    )
}
